// Skills Icons
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import codeIcon from "./images/code.svg"
import ts from "./images/ts.png"
import node from "./images/node.svg"
import docker from "./images/docker.svg"
import github from "./images/github.svg"
import cloud from "./images/cloud.svg"
import linkIcon from "./images/linkIcon.svg"







// Social Icon
import githubIcon from "./images/github.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "DungVu",
  headerTagline: [
    //Line 1 For Header
    "Ready for the",
    //Line 2 For Header
    " ",
    //Line 3 For Header
    "Next Challenge",
  ],
  //   Header Paragraph
  headerParagraph:
    "🇻🇳🇬🇧🇨🇳🇫🇮  Web developer 👨🏻‍💻🍣🍜🎵📈",

  //Contact Email
  contactEmail: "dvu2758@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "Project Chatrum", //Project Title - Add Your Project Title Here
      para:
        "Instant chat app built on React.js & Firebase. Users can authenticate with Google Account ", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://github.com/dannykhoai/chatrum/raw/master/chatrum-test.gif",
      //Project URL - Add Your Project Url Here
      url: "https://dannykhoai.github.io/chatrum/",
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "Project Fotogrid", //Project Title - Add Your Project Title Here
      para:
        "Album app built on React.js & Firebase. Only logged on client can upload pictures. I am using this as personal CDN", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://raw.githubusercontent.com/dannykhoai/fotogrid/master/Screenshot%202021-04-08%20at%200.06.37.png",
      //Project URL - Add Your Project Url Here
      url: "https://dannykhoai.github.io/fotogrid/",
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: "Notepad JS", //Project Title - Add Your Project Title Here
      para:
        "Simple note taking app: Javascript + Jquery + CSS", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/fotogrid-f721f.appspot.com/o/Screenshot%202021-11-04%20at%200.12.48.png?alt=media&token=c391dbc0-9deb-4124-a1d5-f0722ffe12eb",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/dannykhoai/Notepad-JS",
    },
    
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: "Robot Competition", //Project Title - Add Your Project Title Here
      para:
        "Path-following robot written in C . Real time data transfer thru MQTT", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://github.com/dannykhoai/aboutme/blob/master/src/images/zumo.gif?raw=true",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/dannykhoai/Team8ZumoSD2018",
    },
    {
      id: 5, //DO NOT CHANGE THIS (Please)😅
      title: "Webface99", //Project Title - Add Your Project Title Here
      para:
        "Web based Facial detection app with Emotion prediction. Need to allow camera access thru browser (PC & Mac) ", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://github.com/dannykhoai/aboutme/blob/master/src/images/screenshot.png?raw=true",
      //Project URL - Add Your Project Url Here
      url: "https://dannykhoai.github.io/webface99/",
    },
    {
      id: 6, //DO NOT CHANGE THIS (Please)😅
      title: "Street Parking", //Project Title - Add Your Project Title Here
      para:
        "Monitoring parking space availability with RTSP camera stream. Image recognition using custom trained Tensorflow model YOLOV3", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://raw.githubusercontent.com/dannykhoai/streetparking/master/snow%20ok.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/dannykhoai/streetparking",
    },

    
    /*
    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
    ,{
        id: 5,
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],
  works: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "YDesign", //Project Title - Add Your Project Title Here
      para:
        "YDesign studio's webpage using GatsbyJS. Made use of built in GraphQL support & Markdown for quick content editting", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/fotogrid-f721f.appspot.com/o/512x512.jpg?alt=media&token=77ba4aeb-d048-4186-9a5f-efbbead09634",
      //Project URL - Add Your Project Url Here
      url: "https://ydesign.fi",
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "ÔNam", //Project Title - Add Your Project Title Here
      para:
        "Refreshed looking for a popular Vietnamese restaurant chain in Helsinki. Project involved Web & Graphics design", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://github.com/dannykhoai/fotogrid/blob/master/scrnshot.png?raw=true",
      //Project URL - Add Your Project Url Here
      url: "https://onam.fi/",
    },
  ], 

  

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I am in love with the way digital products are timeless, weightless, and are instantly available around the world 👨🏻‍💻 True that, learning to code has been a challenging journey for me, however I have found this skill to be very rewarding.",
  aboutParaTwo:
    "I am aware this is a fast growing, modern industry. Therefore I always try to update my toolkit, to learn and use new & efficient frameworks like React, Next, Gatsby, most recently I picked up styled-components and TailwindCSS and I think these are awesome tools to have. ",
  aboutParaThree:
    "I chose to work as a web developer because I am inspired to be a better self today than I was yesterday. Put simply, I love to learn new things and be able to create impacts.",
  
  aboutImage:
    "https://github.com/dannykhoai/aboutme/blob/master/src/images/me.png?raw=true",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
  
    {
      id: 1,
      img: jsIcon,
      para:
        "Javascript",
    },
    {
      id: 2,
      img: cssIcon,
      para:
        "CSS (and HTML5) ",
    },
    {
      id: 3,
      img: reactIcon,
      para:
        "ReactJS + MaterialUI + styled-components",
    },
    {
      id: 4,
      img: cloud,
      para:
        "Google Cloud + Firebase",
    },
    {
      id: 5,
      img: node,
      para:
        "NodeJS ",
    },
    {
      id: 6,
      img: ts,
      para:
        "Typescript",
    },
 
    {
      id: 7,
      img: docker,
      para:
        "Docker",
    },
    {
      id: 8,
      img: codeIcon,
      para:
        "SSGs: Gatsby & Nextjs",
    },
    {
      id: 9,
      img: github,
      para:
        "GitHub",
    },

    
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Blog",
  promotionPara:
    "My non-code life (coming soon)",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "I enjoy being around talented people like you. What's on your mind?",
  social: [
    // Add Or Remove The Link Accordingly
    { img: linkIcon, url: "https://link.maybevu.com" },

    { img: githubIcon, url: "https://github.com/dannykhoai" },

    {
      img: instagramIcon,
      url: "https://www.instagram.com/maybe_vu/",
    },
  ],

  // End Contact Section ---------------
}

