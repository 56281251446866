import React from "react"
import { navigate } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Navbar = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="navbar-wrapper">
          <div
            role="button"
            onClick={() => navigate("/")}
            className="name"
            tabIndex={0}
          >
            My Portfolio.
          </div>
          <div className="links-wrapper">
          <AnchorLink className="anchor" to="/blog" title="Blog"></AnchorLink>
          <AnchorLink className="anchor" to="/#work" title="Works"></AnchorLink>
          <AnchorLink className="anchor" to="/#about" title="About"></AnchorLink>
          <AnchorLink className="anchor" to="/#contact" title="Contact"></AnchorLink>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
